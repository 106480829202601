<template>
  <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="4" height="4" rx="2" transform="matrix(1 0 0 -1 0 4)" fill="currentColor"/>
    <rect width="4" height="4" rx="2" transform="matrix(1 0 0 -1 6 4)" fill="currentColor"/>
    <rect width="4" height="4" rx="2" transform="matrix(1 0 0 -1 12 4)" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "IconThreeDot"
}
</script>

<style scoped>

</style>
