<template>
  <div>
    <MobileDefault v-if="isMobile">
      <div class="container-bg-white container-space-mobile-default flex justify-space-between">
        <div>
          <h1 class="title"><span class="border-left-line"></span>Báo cáo đã lưu</h1>
          <div class="flex justify-space-between">
            <a-button type="primary" @click="createReport">
              + Tạo Báo cáo
            </a-button>
            <!--          <a-select v-model="numberOfSavedReport" style="width: 176px">-->
            <!--            <a-select-option :value="5">Hiển thị 5 Báo cáo</a-select-option>-->
            <!--            <a-select-option :value="10">Hiển thị 10 Báo cáo</a-select-option>-->
            <!--          </a-select>-->
          </div>
        </div>
        <div class="flex flex-col items-end">
          <a-select v-model="durationDaySelected" class="flex-grow-1 ml-1" style="max-width: 210px" v-if="allDurationDay && allDurationDay.length">
            <template v-for="durationDay in allDurationDay">
              <a-select-option :value="durationDay" :key="durationDay">
                <a-tooltip placement="left">
                  Số liệu {{durationDay}} ngày gần đây
                </a-tooltip>
              </a-select-option>
            </template>
          </a-select>
          <i @click="onClickProductSortPopup" class="mt-2">
            <IconSort width="20" height="20" style="color: #BDBDBD"/>
          </i>
          <PopupSortMobile
            :visible="popupSortSavedReportVisible"
            :sort-metric-array="sortMetricArray"
            sort-metric-default="created_at__desc"
            @select="handleSelectSort"
            @close="popupSortSavedReportVisible = false"
          />
        </div>
      </div>
      <TableSavedReport class="container-bg-white" style="margin: -12px 12px; padding-bottom: 12px"
                        :duration-day-selected="durationDaySelected"
                        :sort-by-prop="sortBy"
                        @updateSavedReport="handleUpdateSavedReport"
                        @changeSortBy="updateSavedReportSort"
      />
    </MobileDefault>
    <Default v-else>
      <div class="container-bg-white container-space-default">
        <div class="flex justify-space-between items-start">
          <div>
            <div class="flex items-center">
              <h1 class="title"><span class="border-left-line"></span>Báo cáo đã lưu</h1>
              <a-popover placement="bottomLeft" trigger="click" title="Tải báo cáo">
                <a-tooltip style="cursor: default" class="pl-1">
                  <template slot="title" placement="right">
                    Tải báo cáo
                  </template>
                  <button style="background: none" class="ml-4 mb-3">
                    <a-icon id="saved-report-download-icon" type="download" class="cursor-pointer hover:text-primary-900" style="font-size: 24px;" />
                  </button>
                </a-tooltip>
                <div slot="content" class="p-2">
                  <div class="flex mb-2 items-center">
                    <span class="mr-2">Định dạng tải xuống: </span>
                    <a-select v-model="downloadType" >
                      <template v-for="type in downloadTypeList">
                        <a-select-option :value="type" :key="type">
                          <span class="uppercase">{{ type }}</span>
                        </a-select-option>
                      </template>
                    </a-select>
                  </div>
                  <p class="text-red-500 mt-1">{{ errorDownload }}</p>
                  <div class="w-full flex justify-end mt-4">
                    <a-button type="primary" @click="downloadSavedReport">
                      <a-icon v-if="isDownloading" type="loading"/>
                      Tải xuống
                    </a-button>
                  </div>
                </div>
              </a-popover>
            </div>
            <div class="flex justify-space-between items-end">
              <div>
                <div class="mb-4">
                  <a-button type="primary" @click="createReport">
                    + Tạo Báo cáo
                  </a-button>
                </div>
                <a-select v-model="numberOfSavedReport" style="width: 176px">
                  <a-select-option :value="5">Hiển thị 5 Báo cáo</a-select-option>
                  <a-select-option :value="10">Hiển thị 10 Báo cáo</a-select-option>
                </a-select>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-end">
            <p class="w-full text-left mb-3">Tùy chỉnh số liệu</p>
            <a-select v-model="durationDaySelected" style="width: 180px" v-if="allDurationDay && allDurationDay.length">
              <template v-for="durationDay in allDurationDay">
                <a-select-option :value="durationDay" :key="durationDay">
                  <a-tooltip placement="left">
                    {{durationDay}} ngày gần đây
                  </a-tooltip>
                </a-select-option>
              </template>
            </a-select>
<!--            <a-popover placement="bottomLeft" trigger="click" title="Sắp xếp" class="mt-6">-->
<!--              <a-button class="relative flex justify-center items-center"-->
<!--                        style="color: #2368D4; background-color: rgba(0, 122, 255, 0.1) !important; border: none;"-->
<!--                        size="large"-->
<!--              >-->
<!--                <IconSort style="color: #2368D4"/>-->
<!--                Sắp xếp-->
<!--              </a-button>-->
<!--              <div slot="content" class="">-->
<!--                <div>-->
<!--                  <a-select style="width: 200px" class="margin-right-20" v-model="savedReportSortValue" v-if="allSavedReportSort && allSavedReportSort.length">-->
<!--                    <a-select-option v-for="searchOption in allSavedReportSort" :key="`sort_by_${searchOption.value}`" :value="searchOption.value">{{ searchOption.label }}</a-select-option>-->
<!--                  </a-select>-->
<!--                  <a-select style="width: 120px" class="margin-right-20" v-model="savedReportSortDirection">-->
<!--                    <a-select-option value="asc">-->
<!--                      <div class="flex items-center">-->
<!--                        {{getSortMetricLowLabel(savedReportSortValue)}}-->
<!--                        <svg class="mx-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.875195 6.99945C0.875195 7.11548 0.921288 7.22676 1.00333 7.30881C1.08538 7.39085 1.19666 7.43695 1.31269 7.43695L11.6316 7.43695L8.87794 10.1897C8.83727 10.2304 8.805 10.2787 8.78299 10.3318C8.76097 10.385 8.74964 10.4419 8.74964 10.4994C8.74964 10.557 8.76097 10.6139 8.78299 10.6671C8.805 10.7202 8.83727 10.7685 8.87794 10.8092C8.91862 10.8499 8.96691 10.8821 9.02006 10.9042C9.07321 10.9262 9.13017 10.9375 9.18769 10.9375C9.24522 10.9375 9.30218 10.9262 9.35533 10.9042C9.40848 10.8821 9.45677 10.8499 9.49744 10.8092L12.9974 7.3092C13.0382 7.26856 13.0705 7.22028 13.0926 7.16713C13.1146 7.11398 13.126 7.05699 13.126 6.99945C13.126 6.9419 13.1146 6.88492 13.0926 6.83177C13.0705 6.77862 13.0382 6.73034 12.9974 6.6897L9.49744 3.1897C9.45677 3.14902 9.40848 3.11676 9.35533 3.09474C9.30218 3.07273 9.24522 3.0614 9.18769 3.0614C9.13017 3.0614 9.07321 3.07273 9.02006 3.09474C8.96691 3.11676 8.91862 3.14902 8.87794 3.1897C8.83727 3.23038 8.805 3.27867 8.78299 3.33181C8.76097 3.38496 8.74964 3.44192 8.74964 3.49945C8.74964 3.55697 8.76097 3.61394 8.78299 3.66708C8.805 3.72023 8.83727 3.76852 8.87794 3.8092L11.6316 6.56195L1.31269 6.56195C1.19666 6.56195 1.08538 6.60804 1.00333 6.69009C0.921288 6.77214 0.875195 6.88342 0.875195 6.99945Z" fill="#898989"/>-->
<!--                        </svg>-->
<!--                        {{getSortMetricHighLabel(savedReportSortValue)}}-->
<!--                      </div>-->
<!--                    </a-select-option>-->
<!--                    <a-select-option value="desc">-->
<!--                      <div class="flex items-center">-->
<!--                        {{getSortMetricHighLabel(savedReportSortValue)}}-->
<!--                        <svg class="mx-1" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.875195 6.99945C0.875195 7.11548 0.921288 7.22676 1.00333 7.30881C1.08538 7.39085 1.19666 7.43695 1.31269 7.43695L11.6316 7.43695L8.87794 10.1897C8.83727 10.2304 8.805 10.2787 8.78299 10.3318C8.76097 10.385 8.74964 10.4419 8.74964 10.4994C8.74964 10.557 8.76097 10.6139 8.78299 10.6671C8.805 10.7202 8.83727 10.7685 8.87794 10.8092C8.91862 10.8499 8.96691 10.8821 9.02006 10.9042C9.07321 10.9262 9.13017 10.9375 9.18769 10.9375C9.24522 10.9375 9.30218 10.9262 9.35533 10.9042C9.40848 10.8821 9.45677 10.8499 9.49744 10.8092L12.9974 7.3092C13.0382 7.26856 13.0705 7.22028 13.0926 7.16713C13.1146 7.11398 13.126 7.05699 13.126 6.99945C13.126 6.9419 13.1146 6.88492 13.0926 6.83177C13.0705 6.77862 13.0382 6.73034 12.9974 6.6897L9.49744 3.1897C9.45677 3.14902 9.40848 3.11676 9.35533 3.09474C9.30218 3.07273 9.24522 3.0614 9.18769 3.0614C9.13017 3.0614 9.07321 3.07273 9.02006 3.09474C8.96691 3.11676 8.91862 3.14902 8.87794 3.1897C8.83727 3.23038 8.805 3.27867 8.78299 3.33181C8.76097 3.38496 8.74964 3.44192 8.74964 3.49945C8.74964 3.55697 8.76097 3.61394 8.78299 3.66708C8.805 3.72023 8.83727 3.76852 8.87794 3.8092L11.6316 6.56195L1.31269 6.56195C1.19666 6.56195 1.08538 6.60804 1.00333 6.69009C0.921288 6.77214 0.875195 6.88342 0.875195 6.99945Z" fill="#898989"/>-->
<!--                        </svg>-->
<!--                        {{getSortMetricLowLabel(savedReportSortValue)}}-->
<!--                      </div>-->
<!--                    </a-select-option>-->
<!--                  </a-select>-->
<!--                </div>-->
<!--                <p class="mt-4"><span class="cursor-pointer" style="color: #2368D4;" @click="resetProductSort">Reset</span></p>-->
<!--              </div>-->
<!--            </a-popover>-->
          </div>
        </div>
        <TableSavedReport class="mt-4"
                          :row-count="numberOfSavedReport"
                          :duration-day-selected="durationDaySelected"
                          :sort-by-prop="sortBy"
                          @updateSavedReport="handleUpdateSavedReport"
                          @changeSortBy="updateSavedReportSort"
        />
      </div>
    </Default>
  </div>
</template>

<script>

import Default from "@/layout/Default";
import MobileDefault from "@/layout/MobileDefault";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
import {MutationSidebar} from "@/store/modules/SidebarModule/mutation";
import TableSavedReport from "@/views/report/TableSavedReport";
import {NOTIFICATION_TYPE} from "@/constant/general/GeneralConstant";
import {toNumber} from "@/helper/StringHelper";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import IconSort from "@/assets/icon/IconSort";
import PopupSortMobile from "@/views/popup/PopupSortMobile";
import {downloadFile} from "@/service/market/MarketService";
import {downloadSavedReport} from "@/service/report/ReportService";
import {PERMISSION} from "@/constant/general/PermissionConstant";

export default {
  name: "SavedReportView",
  components: {
    Default,
    MobileDefault,
    TableSavedReport,
    IconSort,
    PopupSortMobile
  },
  mixins: [
    mixinStoreGetterGlobal,
    mixinStoreGetterPermission,
  ],
  computed: {
    allSavedReportSort() {
      return [
        {
          value: 'report_name',
          label: 'Tên sản phẩm'
        },
        {
          value: 'created_at',
          label: 'Ngày lưu báo cáo'
        },
        {
          value: `order_count_${this.durationDaySelected}d`,
          label: `Tổng đã bán ${this.durationDaySelected} ngày`
        },
        {
          value: `order_revenue_${this.durationDaySelected}d`,
          label: `Tổng doanh số ${this.durationDaySelected} ngày`
        },
        {
          value: 'shop_count',
          label: 'Số shop đã bán'
        },
        {
          value: 'product_count',
          label: 'Số sản phẩm đã bán'
        },
      ]
    },
    sortMetricArray() {
      let metricArray = this.allSavedReportSort
        .filter(item => !['shop_count', 'product_count'].includes(item.value))
        .map(item => {
        return {
          id: item.value,
          label: item.label,
          optionArray: [
            {
              label: `${this.getSortMetricHighLabel(item.value)} > ${this.getSortMetricLowLabel(item.value)}`,
              id: 'desc'
            },
            {
              label: `${this.getSortMetricLowLabel(item.value)} > ${this.getSortMetricHighLabel(item.value)}`,
              id: 'asc'
            },
          ]
        }
      })
      return metricArray
    },
    sortBy() {
      return `${this.savedReportSortValue}__${this.savedReportSortDirection}`
    }
  },
  methods: {
    createReport() {
      this.$router.push({name: 'Home', query: {popup: NOTIFICATION_TYPE.create_saved_report}})
    },
    handleUpdateSavedReport(listSavedReport) {
      if (!listSavedReport?.length) {
        return
      }
      this.allDurationDay = Array.from(
        new Set(
          Object.keys(listSavedReport[0]?.data)
            .filter(item => item.includes('order_'))
            .map(item => toNumber(item))
            .filter(item => item > 0)
            .sort((item1, item2) => item1 - item2)
        )
      )
    },
    updateSavedReportSort(sortBy) {
      if (!sortBy) {
        this.savedReportSortValue = `created_at`
        this.savedReportSortDirection = 'desc'
        return
      }
      [this.savedReportSortValue, this.savedReportSortDirection] = sortBy.split('__')
    },
    resetProductSort() {
      this.updateSavedReportSort()
    },
    onClickProductSortPopup() {
      this.popupSortSavedReportVisible = true
    },
    handleSelectSort(value) {
      this.popupSortSavedReportVisible = false
      this.updateSavedReportSort(value)
    },
    getSortMetricHighLabel(metricId) {
      switch (metricId) {
        case 'report_name':
          return 'Z'
        case 'created_at':
          return `Mới${this.isMobile ? '' : ' nhất'}`
        default:
          return 'Cao'
      }
    },
    getSortMetricLowLabel(metricId) {
      switch (metricId) {
        case 'report_name':
          return 'A'
        case 'created_at':
          return `Cũ${this.isMobile ? '' : ' nhất'}`
        default:
          return 'Thấp'
      }
    },
    async downloadSavedReport() {
      this.isDownloading = true
      this.errorDownload = ''
      let data = await downloadSavedReport(1, 10, this.sortBy, this.downloadType)
      if (!!data?.status && data.status !== '200' && data.status !== 'success') {
        this.isDownloading = false
        this.errorDownload = 'Đã có lỗi xảy ra! Vui lòng thử lại sau hoặc liên hệ hỗ trợ!'
        return
      }
      downloadFile(data, `beecost-market-saved-report`, this.downloadType === 'csv' ? 'csv' : 'xlsx')
      this.isDownloading = false
    }
  },
  watch: {
    permissionAvailableName(value) {
      if (value) {
        if (!this.isStandardBAccount) {
          if (!this.isAgencyOrHigherPriorityAccount || typeof PERMISSION[this.permissionAvailableName].limitSavedReport !== 'number') {
            this.$router.push({name: 'Home'});
            // this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_company_permission)
            return;
          }
        }
      }
    }
  },
  data() {
    return {
      numberOfSavedReport: 10,
      allDurationDay: [7, 30, 90, 180],
      durationDaySelected: 30,
      savedReportSortValue: 'created_at',
      savedReportSortDirection: 'desc',
      isLoadingSavedReportData: false,
      popupSortSavedReportVisible: false,
      downloadTypeList: ['excel', 'csv'],
      downloadType: 'excel',
      isDownloading: false,
      errorDownload: ''
    }
  },
  created() {
    this.$store.commit(`SidebarModule/${MutationSidebar.menuSelected}`, 'SAVED_REPORT')
  },
  mounted() {
    window.scroll(0, 0)
  }
}
</script>

<style scoped lang="scss">
.container-space-mobile-default {

  h1.title {
    font-size: 16px;
    font-weight: 500;

    .border-left-line {
      border-left: 1px solid #ff7227;
      padding-right: 4px;
      font-size: 1rem;
    }
  }
}
</style>
