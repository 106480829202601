<template>
  <div class="table-saved-product" :class="isMobile ? 'mobile-view' : ''">
    <a-table
      :tableLayout="isMobile ? 'fixed' : ''"
      :columns="columns"
      :rowKey="record => record.report_hash"
      :dataSource="dataSource"
      :pagination="isDesktop ? pagination : false"
      :loading="isLoading"
      @change="handleTableChange"
    >
      <template slot="report_name" slot-scope="report_name, report">
        <div class="relative">
          <div class="relative">
            <div class="flex">
              <p class="flex-grow-1 font-medium cursor-pointer"
                 :class="isMobile ? 'line-clamp__1 text-xs' : 'line-clamp__2 text-base mr-2'"
              >
                <a :href="`/?saved-report=${report.report_hash}`" target="_blank">
                  {{report_name}}
                </a>
              </p>
              <i @click="onClickEditSavedReport(report.report_hash)" v-if="isDesktop" class="mt-1">
                <IconEdit class="cursor-pointer hover:text-primary-900" style="top: calc(50% - 10px); color: #7C7C7C"/>
              </i>
            </div>
            <div v-if="!isMobile" v-show="renameReportHash === report.report_hash"
                 class="w-screen h-screen fixed top-0 left-0"
                 @click="renameValue ? popupConfirmCancelRenameReport = true : renameReportHash = null"
            ></div>
            <div v-if="!isMobile" v-show="renameReportHash === report.report_hash"
                 class="absolute mt-1 bg-white px-4 pt-1 pb-4 z-10 rounded shadow-md"
                 style="width: 500px; border: 1px solid #EFEFEF"
            >
              <div class="flex justify-space-between items-end">
                <div class="flex-grow-1 mr-2">
                  <p class="text-right" style="font-size: 10px; color: #A5A5A5;">
                    <span :class="renameValue.length > MAX_LENGTH_OF_REPORT_NAME ? 'text-red-500 text-xs' : ''">{{renameValue.length}}</span>/{{MAX_LENGTH_OF_REPORT_NAME}}
                  </p>
                  <a-input placeholder="Nhập tên mới..." v-model="renameValue"/>
                </div>
                <a-button type="primary" @click="handleRenameReport(report.report_hash, renameValue)">Đổi tên</a-button>
              </div>
              <p v-if="errorRename" class="mt-1 text-red-500">{{errorRename}}</p>
            </div>
          </div>
          <p class="mt-1"><span v-if="isDesktop">Khoảng thời</span><span v-else>Thời</span> gian: {{ getDurationDay(getDateFromOption(report.raw_query.start_date), getDateFromOption(report.raw_query.end_date)) }} ngày</p>
          <p class="mt-1">Từ {{getDateFromOption(report.raw_query.start_date).valueOf() | timestampToDate}} đến {{getDateFromOption(report.raw_query.end_date).valueOf() | timestampToDate}}</p>
          <p class="mt-1 mb-1">
            <template v-if="isDesktop">
              <span>
                Kênh bán:
              </span>
              <img :src="getPlatformById(report.report_search_platform_id).urlLogo"
                   :alt="getPlatformById(report.report_search_platform_id).name"
                   class="img-platform"
              >
            </template>
            <template v-else>
              Ngày lưu: {{new Date(report.created_at).valueOf() | timestampToDate}}
            </template>
          </p>
          <span class="cursor-pointer text-primary-500 underline" @click="onClickViewDetail(report)">Chi tiết lệnh Báo cáo</span>
        </div>
      </template>
      <template slot="created_at" slot-scope="created_at">
        {{new Date(created_at).valueOf() | timestampToDate}}
      </template>
      <template slot="report_search_keyword" slot-scope="report_search_keyword, report">
        <p v-if="report_search_keyword && report_search_keyword.length">
          <span v-for="(keyword, index) in report_search_keyword" :key="`${report.report_hash}_${keyword}`" class="mr-0.5">
            {{keyword}}{{index !== report_search_keyword.length - 1 ? ',' : ''}}
          </span>
        </p>
        <a-icon type="minus" v-if="!report_search_keyword || !report_search_keyword.length" />
      </template>
      <template slot="report_search_platform_id" slot-scope="report_search_platform_id, report">
        <img :src="getPlatformById(report_search_platform_id).urlLogo"
             :alt="getPlatformById(report_search_platform_id).name"
             class="img-platform"
        >
      </template>
      <template slot="order_revenue_selected" slot-scope="order_revenue_selected, report">
        <p>{{order_revenue_selected | formatCurrency}}</p>
        <p v-if="order_revenue_selected && report[`order_revenue_${durationDaySelected}d_previous`]">
          <CompareValue :value="order_revenue_selected" :value-comparison="report[`order_revenue_${durationDaySelected}d_previous`]"/> so với cùng kì
        </p>
      </template>
      <template slot="order_count_selected" slot-scope="order_count_selected, report">
        <p>{{order_count_selected | formatNumberHuman}}</p>
        <p v-if="order_count_selected && report[`order_count_${durationDaySelected}d`]">
          <CompareValue :value="order_count_selected" :value-comparison="report[`order_count_${durationDaySelected}d_previous`]"/> so với cùng kì
        </p>
      </template>
      <template slot="shop_count" slot-scope="shop_count, report">
        <p>{{shop_count | formatNumberHuman}}</p>
      </template>
      <template slot="product_count" slot-scope="product_count, report">
        <p>{{product_count | formatNumberHuman}}</p>
      </template>
      <template slot="action" slot-scope="action, report">
        <a-popover trigger="click" placement="bottomLeft" v-model="popupAction[report.report_hash]">
          <template slot="content">
            <p v-if="isMobile" class="mb-2 cursor-pointer hover:text-primary-900" @click="renameReportHash = report.report_hash">Đổi tên</p>
            <p class="mb-2 cursor-pointer hover:text-primary-900" @click="cloneReportHash = report.report_hash">Tạo bản sao</p>
            <p class="cursor-pointer hover:text-primary-900" @click="deleteReportHash = report.report_hash">Xóa khỏi danh sách</p>
          </template>
          <div class="cursor-pointer hover:text-primary-900 flex items-center py-2" style="color: #C4C4C4">
            <IconThreeDot/>
          </div>
        </a-popover>
      </template>
    </a-table>
    <Pagination :current-page="pagination.current" :total-page="20"
                v-if="isMobile"
                class="container-bg-white px-2 py-3 mt-4" style="color: #8C8C8C; font-size: 10px;"
                @change="changePageNumber"
    />
    <PopupSavedReportDetail
      v-if="popupDetailReport && !isMobile"
      :is-display="popupDetailReport"
      :report-hash="detailReportHash"
      @close="onClosePopupSavedReportDetail"
    />
    <a-modal v-if="popupDetailReport && isMobile" title="Chi tiết lệnh Báo cáo"
             :closable="true"
             :centered="true"
             :footer="null"
             v-model="popupDetailReport"
             :maskClosable="true"
             @cancel="detailReportHash = null"
    >
      <p style="line-height: 26px">
        <span class="font-medium">Tên Báo cáo: </span>
        <span>{{detailReport.report_name}}</span>
      </p>
      <p class="mt-2" style="line-height: 26px">
        <span class="font-medium">Kênh bán: </span>
        <span>{{getPlatformById(detailReport.report_search_platform_id).name}}</span>
      </p>
      <div v-if="detailReport.report_search_keyword && detailReport.report_search_keyword.length" class="mt-2" style="line-height: 26px">
        <p class="font-medium">Từ khóa: </p>
        <p>{{detailReport.report_search_keyword}}</p>
      </div>
      <div class="mt-2" style="line-height: 26px">
        <p class="font-medium">Từ Doanh số 30 ngày gần nhất: </p>
        <p>{{detailReport[`order_revenue_${durationDaySelected}d`] | formatCurrency}}</p>
        <p v-if="detailReport[`order_revenue_${durationDaySelected}d`] && detailReport[`order_revenue_${durationDaySelected}d_previous`]" class="flex">
          <CompareValue class="mr-1" :value="detailReport[`order_revenue_${durationDaySelected}d`]" :value-comparison="detailReport[`order_revenue_${durationDaySelected}d_previous`]"/> so với cùng kì
        </p>
      </div>
      <div class="mt-2" style="line-height: 26px">
        <p class="font-medium">Sản phẩm đã bán 30 ngày gần nhất:</p>
        <p>{{detailReport[`order_count_${durationDaySelected}d`] | formatNumberHuman}}</p>
        <p v-if="detailReport[`order_count_${durationDaySelected}d`] && detailReport[`order_count_${durationDaySelected}d_previous`]" class="flex">
          <CompareValue class="mr-1" :value="detailReport[`order_count_${durationDaySelected}d`]" :value-comparison="detailReport[`order_count_${durationDaySelected}d_previous`]"/> so với cùng kì
        </p>
      </div>
      <div class="mt-2" style="line-height: 26px">
        <p class="font-medium">Số Shop bán: </p>
        <p>{{detailReport.shop_count | formatNumberHuman}}</p>
      </div>
      <div class="mt-2" style="line-height: 26px">
        <p class="font-medium">Số sản phẩm: </p>
        <p>{{detailReport.product_count | formatNumberHuman}}</p>
      </div>
      <div class="flex justify-end mt-5">
        <a :href="`/?saved-report=${detailReportHash}`" target="_blank">
          <a-button type="primary">
            Mở Báo cáo
          </a-button>
        </a>
      </div>
    </a-modal>
    <PopupConfirm
      ok-text="Đồng ý"
      text="Bạn muốn hủy Đổi tên Báo cáo?"
      :is-shown-popup="popupConfirmCancelRenameReport"
      @cancel="popupConfirmCancelRenameReport = false"
      @ok="renameReportHash = null"
    />
    <PopupConfirm
      ok-text="Đồng ý"
      text="Bạn muốn xóa Báo cáo đã lưu?"
      :is-shown-popup="!!deleteReportHash"
      @cancel="deleteReportHash = null"
      @ok="deleteReport(deleteReportHash)"
    />
    <PopupNotification v-if="errorDelete"
                       title="Đã có lỗi xảy ra"
                       :contents="[errorDelete]"
                       @cancel="errorDelete = null"
                       @ok="errorDelete = null"
    />
    <a-modal v-if="isMobile" title="Đổi tên"
             :closable="!renameReportHash"
             :centered="true"
             :footer="null"
             v-model="popupRenameReport"
             :maskClosable="!renameValue"
             @cancel="renameReportHash = null"
    >
      <p v-if="dataSource.find(report => report.report_hash === renameReportHash)">
        Báo cáo: {{dataSource.find(report => report.report_hash === renameReportHash).report_name}}
      </p>
      <p class="text-right" style="font-size: 10px; color: #A5A5A5;">
        <span :class="renameValue.length > MAX_LENGTH_OF_REPORT_NAME ? 'text-red-500 text-xs' : ''">{{renameValue.length}}</span>/{{MAX_LENGTH_OF_REPORT_NAME}}
      </p>
      <a-textarea
        v-model="renameValue"
        placeholder="Nhập tên mới..."
        :auto-size="{ minRows: 2, maxRows: 6 }"
      />
      <p v-if="errorClone" class="mt-1 text-red-500">{{errorClone}}</p>
      <div class="flex justify-end mt-5">
        <a-button class="text-gray-400 mr-2" @click="renameReportHash = null">
          Hủy
        </a-button>
        <a-button type="primary" @click="handleRenameReport(renameReportHash, renameValue)">
          Đổi tên
        </a-button>
      </div>
    </a-modal>
    <a-modal title="Tạo bản sao"
             :closable="!cloneNameValue"
             :centered="true"
             :footer="null"
             v-model="popupCloneReport"
             :maskClosable="!cloneNameValue"
             @cancel="cloneReportHash = null"
    >
      <p v-if="dataSource.find(report => report.report_hash === cloneReportHash)">
        Báo cáo: {{dataSource.find(report => report.report_hash === cloneReportHash).report_name}}
      </p>
      <p class="text-right" style="font-size: 10px; color: #A5A5A5;">
        <span :class="cloneNameValue.length > MAX_LENGTH_OF_REPORT_NAME ? 'text-red-500 text-xs' : ''">{{cloneNameValue.length}}</span>/{{MAX_LENGTH_OF_REPORT_NAME}}
      </p>
      <a-textarea
        v-model="cloneNameValue"
        placeholder="Nhập tên mới..."
        :auto-size="{ minRows: 2, maxRows: 6 }"
      />
      <p v-if="errorClone" class="mt-1 text-red-500">{{errorClone}}</p>
      <div class="flex justify-end mt-5">
        <a-button class="text-gray-400 mr-2" @click="cloneReportHash = null">
          Hủy
        </a-button>
        <a-button type="primary" @click="cloneReport(cloneReportHash, cloneNameValue)">
          Đổi tên
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import {generateColumnsSavedReport, removeColumnsTable} from "@/service/market/MarketService";
import {
  cloneSavedReport,
  deleteSavedReport,
  getListSavedReport,
  updateSavedReport
} from "@/service/report/ReportService";
import {formatCurrency, formatNumberHuman, timestampToDate} from "@/helper/FormatHelper";
import {getPlatformById} from "@/service/platform/PlatformCommonService";
import IconEdit from "@/assets/icon/IconEdit";
import IconThreeDot from "@/assets/icon/IconThreeDot";
import PopupConfirm from "@/views/popup/PopupConfirm";
import PopupNotification from "@/views/popup/PopupNotification";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import moment from "moment";
import {getDateFromOption} from "@/service/search/SearchService";
import {MAX_LENGTH_OF_REPORT_NAME} from "@/constant/general/GeneralConstant";
import PopupSavedReportDetail from "@/views/popup/PopupSavedReportDetail";
import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";
import CompareValue from "@/components/common/CompareValue";
import Pagination from "@/components/common/Pagination";

export default {
  name: "TableSavedReport",
  components: {
    IconEdit,
    IconThreeDot,
    PopupConfirm,
    PopupNotification,
    PopupSavedReportDetail,
    CompareValue,
    Pagination
  },
  mixins: [
    mixinStoreGetterGlobal,
  ],
  props: {
    rowCount: {
      default: 5
    },
    durationDaySelected: {
      default: 30
    },
    sortByProp: {
      default: ''
    }
  },
  computed: {
    dataSource() {
      return this.listSavedReport?.map(item => {
        return {
          report_hash: item.report_hash,
          ...item.data
        }
      }) || []
      .filter(item => !!item)
    }
  },
  methods: {
    getPlatformById,
    getDateFromOption,
    getDurationDay(startDate, endDate) {
      if (!startDate || !endDate) {
        return ''
      }
      return moment(endDate).diff(moment(startDate), "days") + 1
    },
    async handleTableChange(pagination, filters, sorter) {
      if (sorter != null && Object.keys(sorter).length > 0) {
        let sort = sorter?.['field']
        if (sorter['order'] === 'ascend') {
          sort += `__asc`
        } else if (sorter['order'] === 'descend') {
          sort += `__desc`
        } else {
          return
        }
        this.sortBy = sort
      }
      if (pagination && this.pagination) {
        this.pagination.pageSize = pagination.pageSize
        this.pagination.current = pagination.current
      }
      await this.updateListSavedReport()
    },
    async changeSortBy(sortBy) {
      if (sortBy?.split('__')[0]) {
        this.sortBy = sortBy
      }
      await this.updateListSavedReport()
    },
    async changePageNumber(pageNumber) {
      this.pagination.current = pageNumber
      await this.updateListSavedReport()
    },
    updateTableColumn() {
      this.columns = generateColumnsSavedReport(this.durationDaySelected)
      if (this.isDesktop) {
        this.columns = removeColumnsTable(this.columns, 'report_search_platform_id')
      } else {
        this.columns = removeColumnsTable(this.columns, 'created_at')
        this.columns = removeColumnsTable(this.columns, 'report_search_keyword')
        this.columns = removeColumnsTable(this.columns, 'shop_count')
        this.columns = removeColumnsTable(this.columns, 'product_count')
        this.columns = this.columns.map(item => {
          item.sorter = false
          if (item.dataIndex === 'report_name') {
            item.width = '40%'
          }
          if (item.dataIndex.indexOf('order_count') === 0) {
            item.title = item.title.replace('Sản phẩm', 'Sp')
          }
          if (item.dataIndex.indexOf('order_') === 0) {
            item.width = '70px'
          }
          if (item.dataIndex.indexOf('action') === 0) {
            item.width = '20px'
          }
          return item
        })
      }
    },
    async updateListSavedReport() {
      this.isLoading = true
      // window.scroll(0, 0)
      this.scrollToTop()
      this.listSavedReport = await getListSavedReport(this.pagination.current, this.pagination.pageSize, this.sortBy)
      this.$emit('updateSavedReport', this.listSavedReport)
      this.isLoading = false
    },
    onClickReportName(reportHash) {
      this.$router.push({name: 'Home', query: {'saved-report': reportHash}})
    },
    onClickEditSavedReport(reportHash) {
      this.renameReportHash = reportHash
    },
    async handleRenameReport(reportHash, name) {
      if (!reportHash) {
        return
      }
      if (!name) {
        this.errorRename = 'Vui lòng đổi tên bản sao Báo cáo!'
        return
      }
      if (name.length > this.MAX_LENGTH_OF_REPORT_NAME) {
        this.errorRename = `Tên báo cáo cần dưới ${this.MAX_LENGTH_OF_REPORT_NAME} kí tự!`
        return
      }
      let reportHashResponse = (await updateSavedReport(reportHash, name)).report_hash
      if (reportHashResponse) {
        await this.updateListSavedReport()
        this.renameReportHash = null
      } else {
        this.errorRename = 'Chưa thể đổi tên báo cáo, xin vui lòng thử lại sau!'
      }
    },
    async cloneReport(reportHash, name) {
      if (!reportHash) {
        return
      }
      if (!name) {
        this.errorClone = 'Nhập tên mới của báo cáo để đổi tên!'
        return
      }
      if (name.length > this.MAX_LENGTH_OF_REPORT_NAME) {
        this.errorClone = `Tên báo cáo cần dưới ${this.MAX_LENGTH_OF_REPORT_NAME} kí tự!`
        return
      }
      let reportHashResponse = (await cloneSavedReport(reportHash, name)).report_hash
      if (reportHashResponse) {
        await this.updateListSavedReport()
        this.cloneReportHash = null
      } else {
        this.errorClone = 'Chưa thể đổi tên báo cáo, xin vui lòng thử lại sau!'
      }
    },
    async deleteReport(reportHash) {
      if (!reportHash) {
        return
      }
      let reportHashResponse = (await deleteSavedReport(reportHash)).report_hash
      if (reportHashResponse) {
        await this.updateListSavedReport()
        this.deleteReportHash = null
      } else {
        this.deleteReportHash = null
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupNotification}`, true)
        this.errorDelete = 'Chưa thể xoá báo cáo, xin vui lòng thử lại sau!'
      }
    },
    async onClickViewDetail(reportDetail) {
      await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.APPLY_OPTIONS}`, {
        ...reportDetail.raw_query,
        isSavedReport: true
      })
      this.detailReportHash = reportDetail.report_hash
      this.detailReport = reportDetail
    },
    async onClosePopupSavedReportDetail() {
      await this.$store.dispatch(`${MutationSearchFilter.MODULE_PATH}/${MutationSearchFilter.RESET}`)
      this.detailReportHash = null
    },
    scrollToTop() {
      this.$el.scrollIntoView({block: 'start', inline: 'start'})
    }
  },
  filters: {
    formatCurrency(value) {
      if (value === 0) {
        return '0₫'
      }
      if (!value) {
        return 'Đang cập nhật ...'
      }
      return formatCurrency(value)
    },
    formatNumberHuman(value) {
      if (value === 0) {
        return '0'
      }
      if (!value) {
        return 'Đang cập nhật ...'
      }
      return formatNumberHuman(value)
    },
    timestampToDate: timestampToDate
  },
  watch: {
    rowCount(value) {
      this.pagination.pageSize = value
      this.pagination.current = 1
      this.updateListSavedReport()
    },
    durationDaySelected(value) {
      this.isLoading = true
      this.updateTableColumn()
      this.isLoading = false
    },
    renameReportHash(value) {
      this.popupRenameReport = !!value
      if (value) {
        this.errorRename = ''
        this.renameValue = ''
        this.popupAction[value] = false
      } else {
        this.popupConfirmCancelRenameReport = false
      }
    },
    deleteReportHash(value) {
      if (value) {
        this.errorDelete = ''
        this.popupAction[value] = false
      }
    },
    cloneReportHash(value) {
      this.popupCloneReport = !!value
      if (value) {
        this.errorClone = ''
        this.cloneNameValue = ''
        this.popupAction[value] = false
      }
    },
    detailReportHash(value) {
      this.popupDetailReport = !!value
    },
    sortByProp(value) {
      this.changeSortBy(value)
    },
    sortBy(value) {
      this.$emit('changeSortBy', value)
    }
  },
  data() {
    return {
      listSavedReport: [],
      columns: null,
      pagination: {
        'current': 1,
        'pageSize': this.rowCount,
        'total': 100,
        'simple': true,
      },
      sortBy: this.sortByProp,
      isLoading: false,
      cloneReportHash: null,
      cloneNameValue: '',
      errorClone: '',
      deleteReportHash: null,
      errorDelete: '',
      renameReportHash: null,
      renameValue: '',
      errorRename: '',
      detailReportHash: null,
      detailReport: null,
      MAX_LENGTH_OF_REPORT_NAME,
      popupDetailReport: false,
      popupRenameReport: false,
      popupConfirmCancelRenameReport: false,
      popupCloneReport: false,
      popupAction: [],
    }
  },
  async mounted() {
    this.updateTableColumn()
    this.isLoading = true
    this.listSavedReport = await getListSavedReport(this.pagination.current, this.rowCount)
    this.$emit('updateSavedReport', this.listSavedReport)
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">
.img-platform {
  width: 25px;
  height: 25px;
  border-radius: 9999px;
  object-fit: cover;
}
.mobile-view {
  .img-platform {
    width: 20px;
    height: 20px;
  }
  /deep/ .ant-table-thead > tr > th {
    font-size: 12px !important;
    padding: 6px 2px !important;
    font-weight: 400;
    background: unset;

    &:first-child {
      padding-left: 8px !important;
    }
  }

  /deep/ .ant-table-tbody > tr > td {
    font-size: 10px !important;
    padding: 8px 2px !important;

    &:first-child {
      padding-left: 8px !important;
    }

    &:last-child {
      padding-right: 8px !important;
    }
  }

  /deep/ .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
}
</style>
